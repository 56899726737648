<template>
  <div class="verification-wrap">
    <el-form ref="resetForm"
             :model="resetForm"
             :rules="resetRules">
      <el-form-item prop="phonenumber">
        <el-input ref="phonenumber"
                  clearable
                  v-model="resetForm.phonenumber"
                  placeholder="请输入手机号"
                  disabled></el-input>
      </el-form-item>
      <el-form-item prop="verification">
        <el-input placeholder="请输入验证码"
                  ref="verification"
                  v-model="resetForm.verification">
          <el-button class="verification-btn"
                     type="primary"
                     slot="append"
                     :disabled="verifibtn"
                     @click="getverificate">{{ vervalue ? `重新获取(${vervalue})` : "获取验证码" }}</el-button>
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input placeholder="请设置密码"
                  ref="password"
                  clearable
                  type="password"
                  show-password
                  v-model="resetForm.password">
        </el-input>
      </el-form-item>
      <el-form-item prop="confirmpassword">
        <el-input placeholder="请确认密码"
                  ref="password"
                  clearable
                  type="password"
                  show-password
                  v-model="resetForm.confirmpassword">
        </el-input>
      </el-form-item>
      <el-button :loading="loading"
                 class="loginbtn"
                 type="primary"
                 @click.native.prevent="handleLogin">确认重置</el-button>
    </el-form>
  </div>
</template>
<script>
import { isPhonenumber } from "../../../utils/validate";
import navigation from "../../../utils/navigation";
import { Message } from 'element-ui';
import md5 from "js-md5";
export default {
  data () {
    const validatePhonenumber = (rule, value, callback) => {
      if (!isPhonenumber(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    const validateVerifica = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入密码"));
      } else {
        if (this.resetForm.confirmpassword.length !== 0) {
          this.$refs.resetForm.validateField("confirmpassword");
        }
        callback();
      }
    };
    const confirmPassword = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.resetForm.password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      resetForm: {
        phonenumber: "",
        verification: "",
        password: "",
        confirmpassword: "",
      },
      resetRules: {
        phonenumber: [
          { required: true, trigger: "blur", validator: validatePhonenumber },
        ],
        verification: [
          { required: true, trigger: "blur", validator: validateVerifica },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
        confirmpassword: [
          { required: true, trigger: "blur", validator: confirmPassword },
        ],
      },
      loading: false,
      vervalue: "",
      verifibtn: false,
    };
  },
  mounted () {
    this.resetForm.phonenumber = this.$store.state.user.phonenumber;
    this.$store.dispatch("user/getuserinfo").then((res) => {
      if (res.RetCode == 0) {
        if (res.PhoneNumber) {
          this.resetForm.phonenumber = res.PhoneNumber;
        }
      }
    });
    if (this.resetForm.phonenumber === "") {
      this.$refs.phonenumber.focus();
    } else if (this.resetForm.verification === "") {
      this.$refs.verification.focus();
    }



  },
  methods: {
    navlogin () {
      navigation.navigateTo({
        url: 'login'
      })
    },
    handleLogin () {
      this.$refs.resetForm.validate((valid) => {
        console.log(valid);
        if (valid) {
          this.loading = true;
          const data = {
            PhoneNumber: this.resetForm.phonenumber,
            VerificationCode: this.resetForm.verification,
            Password: md5(this.resetForm.password),
          };
          this.$store
            .dispatch("user/userresetpass", { ...data })
            .then((res) => {
              if (res.RetCode == 0) {
                this.$message.success("已重置")
                //  this.navlogin()
                
                navigation.redirectTo({ url: "personalcenter" })
              }
              if (res.RetCode == 5) {
                this.$message.error("验证码错误");
              }
              // if (res.RetCode == 6) {
              //   this.$message.error("该手机号已被注册");
              // }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getverificate () {
      this.verifibtn = true;
      var vartime = 60;
      var timer = setInterval(() => {
        vartime--;
        this.vervalue = `${vartime}s`;
        if (vartime == 0) {
          clearInterval(timer);
          this.verifibtn = false;
          this.vervalue = "";
        }
      }, 1000);
      var payload = {
        PhoneNumber: this.resetForm.phonenumber,
      };
      this.$store.dispatch("user/sendvertification", { ...payload });
      // TODO  发送成功后调用
      this.$message({
        showClose: true,
        message: '验证码发送成功',
        showClose: false,
        type: 'success'
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.verification-wrap {
  margin-top: 35px;
  .el-form-item {
    margin-bottom: 40px;
  }
  ::v-deep .el-input__inner {
    border-radius: 5px;
  }
  ::v-deep .el-input-group__append {
    border: none;
  }
  .loginbtn {
    font-size: 16px;
    width: 100%;
    border-radius: 5px;
    background: rgba(88, 151, 242, 1);
  }
  .verification-btn {
    width: 110px;
    border-radius: 5px;
    border: none;
    color: #fff;
    background: rgba(88, 151, 242, 1);
  }
  .accounttext {
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    .register {
      color: rgba(74, 99, 240, 0.93);
      cursor: pointer;
    }
  }
}
</style>